<template>
	<div>
		
		<p class="text-xs p-4"><span class="uppercase text-indigo-500">New</span> Your calendar will only show confirmed gigs, to see gigs you've responded to, see <router-link to="/artist/offers" class="text-gp_pink-default-500 uppercase">new gigs</router-link> and <router-link class="text-gp_pink-default-500 uppercase"  to="/artist/awaiting-venue-response">availability given</router-link>.</p>
		<!-- Calendar -->
		<CalendarWrapper
			calendar-type="ArtistGigs"
			:view-options="calendarViewOptions"
			:available-status-keys="calendarAvailableStatusKeys"
			:schedules="schedules"
      :weekStartDay="toggleWeekStartDay"
			@selected="scheduleSelected"
			:isReadOnly="true"
			@change="changeArtist()"
			id="calendarWrapper"
      @update="update"
      v-on:statusFiltered="filterByStatus($event)"
		>
			<template v-slot:filters>
				<!-- User Artists filter -->
				<TRichSelect 
					id="artist-selection" 
					ref="artistpicker" 
					name="artist-selection" 
					v-model="filteredArtists"
					placeholder="Filter by artist"                    
					:options="artists"
					value-attribute="id" 
					text-attribute="name" 
					class="w-full"
					@change="changeArtist()"
				/>
			</template>

      <template v-slot:weekStartDay>
        <div class="flex items-center justify-end pt-1 pb-4 col-span-full">
            <span class="mr-4 text-sm md:text-md">Start week on</span>
            <select
                v-model="toggleWeekStartDay"
                @change="onToggleWeekStartDay"
                class="px-3 py-1 border rounded-md text-sm md:text-md w-36"
            >
                <option :value="true">Monday</option>
                <option :value="false">Sunday</option>
            </select>
        </div>
      </template>
		</CalendarWrapper>
		<!-- Schedule modal -->
		<calendar-preview 
			ref="calendarPreview" 
			:view-type="'ARTIST'"
			:bid-id="selectedBidId"
			:show-as-bid="true"
		/>

		<CalendarHover ref="calendarhover" id="tooltip"/>
	</div>

</template>

<style>
.tui-full-calendar-month-creation-guide,
.tui-full-calendar-weekday-grid-line{
	background-color: transparent !important;
	border: 1px solid rgba(17, 24, 39, 0.03) !important;
}

</style>

<script>
import NProgress from 'nprogress'
import { TRichSelect } from 'vue-tailwind/dist/components';
import CalendarWrapper from '@/components/calendar/CalendarWrapper.vue';
import CalendarPreview from '@/components/modal/CalendarPreview.vue';
import CalendarHover from '@/components/modal/CalendarHover.vue';
import { getStatusesFromTypes, getStatusFromType } from '@/state/models/bidStatus'
import normalizeDate from '@/utils/normalizeDate';
import { apiMethods } from '@/state/helpers.js';
import client from "@/utils/client";
import {mapState} from "vuex";
const { DateTime } = require("luxon");

const ARTIST_FILTER_KEY = "CALENDAR_ARTIST_FILTER";
const DATE_RANGE_KEY = "CALENDAR_DATE_RANGE";

export default {
	components:{
		TRichSelect,
		CalendarWrapper,
		CalendarPreview,
		CalendarHover
	},
	data(){
		return{
			mobile: false,
			artistbids:null,
			artists:null,
			schedules:[],
			filteredBids:[],
      statusFilters:[
        'CANCELLED',
        'CONFIRMED',
        'AWAITING_SIGN_OFF',
        'SIGNED_OFF',
        'CANCELLATION_REQUESTED_BY_ARTIST'
      ],
			filteredArtists: "all",
			isLoading:false,
			selectedBidId:null,
      dateRange: null,
			calendarViewOptions:[
				{ value: 'month', text: 'Month view'}, 
				{ value: 'week', text: 'Week view'},
				{ value: 'day', text: 'Day view'},
			],
			calendarAvailableStatusKeys: getStatusesFromTypes([
				'CANCELLED',
				'CONFIRMED',
				'AWAITING_SIGN_OFF',
				'SIGNED_OFF',
			]),
      toggleWeekStartDay: false,
		}
	},
	computed:{
    ...mapState({
      calendarStartDay: (state) => state.calendarStartDay,
    }),
		bankHolidays() {
			return this.$store.getters['bankHolidays/bankHolidays']
		},
	},
  watch: {
    dateRange(newVal, oldVal) {
      // Only trigger load if we have actual date changes
      if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
        localStorage.setItem(DATE_RANGE_KEY, JSON.stringify(this.dateRange));
        this.loadBids();
      }
    },
    statusFilters() {
      if (this.artistbids) {
        this.parseBids();
      }
    },
    filteredArtists(newVal, oldVal) {
      if (newVal === oldVal) return;
      
      if (newVal) {
        localStorage.setItem(ARTIST_FILTER_KEY, newVal);
      }
      if (this.dateRange) {
        this.loadBids();
      } else {
        this.setCalendarItems();
      }
    }
  },
	methods:{
		...apiMethods,
    filterByStatus(event)
    {
      this.statusFilters = event;
    },
    initialDate() {
      const date = new Date()

      return {
        date_from: new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substring(0, 10),
        date_to: new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString().substring(0, 10)
      }
    },
    update(val) {
      const date = new Date(val.currentDate)

      this.dateRange = {
        date_from: new Date(date.getFullYear(), date.getMonth(), 1).toISOString().substring(0, 10),
        date_to: new Date(date.getFullYear(), date.getMonth() + 1, 1).toISOString().substring(0, 10)
      }
    },
    loadBids() {
      this.isLoading = true;
      NProgress.start();
      
      if (this.filteredArtists != "all" && this.artists &&
       this.artists.map(artist => artist.id).includes(parseInt(this.filteredArtists)) == false
      ) {
        console.log("No such artist", this.filteredArtists);
        localStorage.setItem(ARTIST_FILTER_KEY, "all");
        this.filteredArtists = "all";
      }
      
      this.$store.dispatch('artistBids/loadArtistBids', {
        date_from: this.dateRange.date_from, 
        date_to: this.dateRange.date_to,
        artist_id: this.filteredArtists !== "all" ? parseInt(this.filteredArtists) : null
      })
      .then((response) => {
        this.artistbids = response;
        this.parseBids();
      })
      .finally(() => {
        this.isLoading = false;
        NProgress.done();
      });
    },
    load() {
      this.isLoading = true;
      NProgress.start();

      Promise.all([
        // Artists
        this.$store.dispatch('artists/getArtists')
            .then((response) => {
              this.artists = [
                { name:"All artists", id:"all" },
                ...response
              ];
            }),
        // Bank Holidays
        this.$store.dispatch('bankHolidays/getBankHolidays')
      ]).then(() => {
        return this.loadBids();
      }).finally(() => {
        this.setupMobileAndTooltip();
      });
    },
    setupMobileAndTooltip() {
      this.mobile = window.matchMedia("(max-width:768px)").matches;

      if (!this.mobile) {
        const tooltip = document.querySelector('#tooltip');
        const calendarWrapper = document.getElementById('calendarWrapper');
        
        if (!calendarWrapper) return;

        calendarWrapper.addEventListener('mouseover', (e) => {
          const schedule = e.target.closest('.tui-full-calendar-weekday-schedule');
          if (!schedule) {
            this.$refs.calendarhover.tooltip = false;
            return;
          }

          const scheduleBidID = schedule.getAttribute('data-schedule-id');
          const gigObj = this.schedules.find(gig => 
            Object.hasOwn(gig, 'raw') && gig.raw.id === parseInt(scheduleBidID)
          );

          if (!gigObj) return;

          const obj = { ...gigObj.raw.gig, status: gigObj.raw.status };
          this.$refs.calendarhover.isArtistView = true;
          this.$refs.calendarhover.item = obj;
          this.$refs.calendarhover.artistBid = gigObj.raw;
          this.$refs.calendarhover.tooltip = true;

          this.getArtist(gigObj.raw.artist.id)
            .then((resp) => {
              if (resp.image !== null) {
                this.$refs.calendarhover.bookedArtist = resp.image.url;
              }
            });

          this.updateTooltipPosition(e, tooltip);
        });
      }
    },
    updateTooltipPosition(e, tooltip) {
      let top = e.pageY - 220;
      let left = e.pageX - 260;

      if (e.pageY < 400) top = e.pageY;
      if (e.pageY > 750) top = e.pageY - 220;
      if (e.pageX > 840) left = e.pageX - 680;
      if (e.pageX < 494) left = e.pageX - 260;

      Object.assign(tooltip.style, { top: `${top}px`, left: `${left}px` });
    },
		changeArtist(){
      // The watcher will handle localStorage and reloading
      this.filteredArtists = this.$refs.artistpicker.value;
		},
		parseBidToSchedule(bid){
			const bidStatus = getStatusFromType(bid.status)
			let end = bid.gig.end;
			
			
			let startHour = DateTime.fromISO(bid.gig.start).toUTC().hour;
			let endHour = DateTime.fromISO(bid.gig.end).toUTC().hour;

			if (endHour < startHour) {
				end = DateTime.fromISO(bid.gig.start).toUTC().set({hours: 23, minutes: 59});
			}
			return {
				id: bid.id,
				title: bidStatus.name,//bid.gig.name,
				category: 'time',
				start: normalizeDate(bid.gig.start),
				end: normalizeDate(end),
				calendarId: 0,
				raw: bid,
				bgColor: bidStatus.bg,
				color: bidStatus.color,
				customStyle: `background-color:${ bidStatus.bg }; color:${ bidStatus.color }!important;`,
				status: bidStatus.name,
			}
		},
		parseBids(){
			if (!this.artistbids) {
				this.filteredBids = [];
				this.setCalendarItems();
				return;
			}

			this.filteredBids = this.artistbids
				.filter((ab) => {
					return ab.gig && ab.status && this.statusFilters.filter(i => i === ab.status).length > 0
				})
				.map((fab) => {
					return this.parseBidToSchedule(fab)
				})

			this.setCalendarItems()
		},

		setCalendarItems(){
			if (!this.filteredBids) return;
			
			let schedules = [ ...this.filteredBids ];
			// check for filter
			if(this.filteredArtists && this.filteredArtists !== "all"){ 
				schedules = schedules.filter(s => s.raw.artist.id === parseInt(this.filteredArtists));
			}

			const bankHolidays = [...this.bankHolidays]
				.map((bankHoliday) => {
					return {
						title: bankHoliday.region === 'England and Wales' ? bankHoliday.title : `${bankHoliday.title} (${bankHoliday.region})`,
						category: 'time',
						start: bankHoliday.date,
						calendarId: 0,
						bgColor: '#E0BBE4',
						color: '#E0BBE4',
						customStyle: 'background-color:' + '#E0BBE4'
					}
				});

			this.schedules = [...schedules, ...bankHolidays];
		},

		scheduleSelected(schedule){
			const selectedBid = this.artistbids.filter(ab => ab.id === schedule.id)[0];
			if(selectedBid){
				this.selectedBidId = selectedBid.id;
				let preview = this.$refs.calendarPreview;
				const obj =  { ...selectedBid.gig, status: selectedBid.status }

				this.getArtist(schedule.raw.artist.id)
					.then((resp) => {
						preview.bookedArtist = resp.image.url;
					});

				preview.artistBid = schedule.raw;
				preview.item = obj;
				this.$refs.calendarhover.tooltip = false;
				preview.viewModal = true;
			}			
		},
    onToggleWeekStartDay(event) {
      this.toggleWeekStartDay = event.target.value === "true";
      this.$store.dispatch("setCalendarStartDay", this.toggleWeekStartDay);
      this.setWeekStartDay();
    },
    setWeekStartDay() {
      client.put("user/update-calendar-start/", {
        state: this.toggleWeekStartDay,
      }).then(() => {
        this.$emit("weekStartDayChanged", this.toggleWeekStartDay);
      });
    },
    restoreStoredState() {
      // Restore date range
      const storedDateRange = localStorage.getItem(DATE_RANGE_KEY);
      if (storedDateRange) {
        try {
          this.dateRange = JSON.parse(storedDateRange);
        } catch (e) {
          console.error('Error parsing stored date range:', e);
          this.dateRange = this.initialDate();
        }
      }

      // Restore artist filter
      const storedArtist = localStorage.getItem(ARTIST_FILTER_KEY);
      if (storedArtist) {
        // Convert to number if it's not "all"
        this.filteredArtists = storedArtist === "all" ? "all" : parseInt(storedArtist);
      }
    },
	},

	mounted(){
      this.toggleWeekStartDay = !!this.calendarStartDay;
      
      // Restore stored state
      this.restoreStoredState();
      
      // Set initial date if needed
      if (!this.dateRange) {
        this.dateRange = this.initialDate();
      }
      
      // Initial load
      this.load();
	}
}
</script>
